@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.TextComponent {
	@include coloredComponent;
	padding: var(--spacing--5xl) 0;
}

.TextComponent_kicker {
	padding-bottom: var(--spacing--3xl);
}

.TextComponent_heading {
	margin-bottom: var(--spacing--lg);

	@include mq("md") {
		margin-bottom: var(--spacing--xl);
	}
}

.TextComponent_content {
	padding-top: 0;

	@include mq("md") {
		padding-top: var(--spacing--base);
		width: 50%;
	}
}

// Theme: Kogp
[data-theme='kogp'] {
	.TextComponent_kicker {
		font-weight: var(--font-weight--extrablack);
	}
}